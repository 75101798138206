<template>
  <div class="h-screen w-full py-6">
    <div class="pb-3 text-center ">
      <p class="font-bold text-xl text-black">{{$t('Price Details')}}</p>
    </div>
    <div class="border-line m-auto md:w-1/4 w-1/2 flex pt-3 justify-between">
      <div class="text-left">
        <p class="text-darkblue">{{$t('Amount')}}</p>
        <p class="text-darkblue">{{$t('Fees')}}</p>
        <p class="text-darkblue">{{$t('Vat (14%)')}}</p>
        <p class="text-darkblue">{{$t('Total')}}</p>
      </div>
      <div class="text-right">
        <p class="font-bold text-black">{{ transaction.amount ? transaction.amount.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : 0.00 }}</p>
        <p class="font-bold text-black">{{ transaction.fees ? transaction.fees.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : 0.00 }}</p>
        <p class="font-bold text-black">{{ transaction.vat ? transaction.vat.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : 0.00 }}</p>
        <p class="font-bold text-black">{{ transaction.total ? transaction.total.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : 0.00 }}</p>
      </div>
    </div>
    <iframe id="paymob-iframe" width="100%" height="100%" :src="transaction.paymobcctransaction.payment_url"/>
  </div>
</template>

<script>
import { sendRequest } from '../../http/axios/requestHelper'
export default {
  data () {
    return {
      transaction: {
        paymobcctransaction: {
          payment_url: ''
        }
      }
    }
  },
  methods: {
    loadTransaction () {
      sendRequest(true, false, this, `api/v1/payments/transactions/${this.$route.params.transactionID}/`, 'get', null, true,
        (response) => {
          this.transaction = response.data
        }
      )
    }
  },
  created () {
    this.loadTransaction()
  }
}
</script>

<style scoped>
#paymob-iframe {
  border: unset !important
}
.border-line {
  border-top: solid 2px #E4E4E4;
}
</style>